<template>
	<div class="gallery pb-12 pt-5">
		<v-container class="vuetify-container flex-center flex-column">
			<h1 class="section-title playfair w-100 dark-text mb-0 px-3" data-aos="fade-left" data-aos-duration="700">Galerija</h1>
			<v-divider class="mb-md-10 mb-5 w-100"></v-divider>

			<vue-masonry-wall :items="images" :options="options" class="w-100 mb-10">
				<template v-slot:default="{item}">
					<img :src="require('../assets/img/opt/general/' + item + '.jpg')" alt="Gallery item" class="gallery-item pointer" data-aos="fade-up" data-aos-duration="700" :style="calcHeight()" @click="openImage">
				</template>
			</vue-masonry-wall>
		</v-container>

		<v-dialog v-model="fullImage" fullscreen>
			<v-card class="flex-center flex-column relative" tile>
				<v-btn icon @click="fullImage = false" style="position: absolute; right: 20px; top: 20px;">
					<v-icon x-large>mdi-close</v-icon>
				</v-btn>
				<img :src="imgSrc" alt="Villa Marina" style="max-width: 100%; height: auto;">
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import VueMasonryWall from "vue-masonry-wall";

export default {
	name: 'Gallery',
	components: {VueMasonryWall},
	computed: {
		images: function() {
			let images = [];
			for(var i=1; i < 33; i++) {
				images.push(i)
			}

			return images;
		}
	},
	methods: {
		openImage: function(e) {
			this.imgSrc = e.target.src;
			this.fullImage = true;
		},
		calcHeight: function() {
			let rand = Math.floor(Math.random() * (600-200+1)) + 300;

			return 'height: ' + rand + 'px;'
		}
	},
	data: () => ({
		imgSrc: null,
		fullImage: false,
		options: {
			width: 500,
			padding: {
				default: 12
			}
		}
	})
}
</script>
<style lang="scss">
.gallery-item {
	width: 100%;
	object-fit: cover;
}
</style>